<template>
  <v-text-field
    v-model="newValue"
    :label="label"
    :prefix="prefix === '-' ? null : prefix"
    :suffix="suffix"
    outlined
    :name="Math.random()"
    dense
    hide-details
    autocomplete="off"
    :class="[classAdd]"
    @keyup="syncInput"
    @keypress="$NumberOnly"
  />
</template>

<script>
export default {
  props: {
    classAdd: {
      type: String,
      default: 'mb-4'
    },
    decimal: {
      type: Number,
      default: 0
    },
    prefix: {
      type: String,
      default: 'Rp'
    },
    suffix: {
      type: String,
      default: ''
    },
    value: {
      type: Number,
      default: 0
    },
    label: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    newValue: ''
  }),
  watch: {
    value: function (v) {
      this.newValue = this.$formatMoney(parseInt(v), 0, '.', ',')
    }
  },
  mounted () {
    this.newValue = this.$formatMoney(parseInt(this.value), 0, '.', ',')
  },
  methods: {
    syncInput () {
      const newInt = this.decimal ? this.newValue.split(',').join('').split('.').join(',') : parseInt(this.newValue.split(',').join('').split('.').join(''))
      this.$emit('input', newInt || 0)
      this.$emit('change', newInt || 0)
      this.$emit('keyup', newInt || 0)
    }
  }
}
</script>

<style lang="scss">
</style>
